.center {
    justify-content: center;
    width: 100% !important;
}

.student-card {
    min-width: 300px;
    margin: 20px;
    padding: 10px;
    border-radius: 5%;
    text-align: center;
    background-color: lightgrey;
}

.bio {

    border-bottom: 2px solid black;
    padding-bottom: 4px;
    margin-bottom: 4px;
}

.stats-box {
    border-bottom: 2px solid black;
    padding-bottom: 4px;
    margin-bottom: 4px;
    width: 100%;
    float: left;
}
.stats {
    width: 100%;
}

.profile-img {

    height: 75px;
    width: 75px;
    border-radius: 50%;
    object-fit: cover;
}

.accepted {
    width: 100%;
    background-color: black;


}

.school-img {
    margin-top: 5px;
    float: left;
    height: 40px;
    width: 40x;

}
.tooltip {
    position: relative;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: green;
    color: #fff;
    text-align: center;
    font-weight: bold;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

.MuiTypography-h6 {
    font-weight: bold !important;
}
