.home-container {
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center; 
    position: relative;
    background: linear-gradient(to bottom right, #616aec, #2d8d4d, #c7a073);
  }

  
  .banner {
    position: absolute;
    background: linear-gradient(to bottom right, #2f3cf1, #2d8d4d, #c7a073);
  height: 30vh;
  width: 40vh;
  }

  .banner-items{
      align-items: center;
      text-align: center;
      width: 100%;
      justify-content: center;
  }

  .Message {
    width: 100%;


    text-align: center;
  }
  
  .header {
    color: white;
    position: absolute;
    z-index: 999;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  }
  
  .content {
    text-align: center;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
  }

  .slide {
    width: 300px;
  height: 200px;
  object-fit: contain;
  align-items: left;
  justify-content: left;
  }
    
  .banner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 400px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;

  }
  .banner-text {
    font-size: 4rem !important;
    font-weight: bold !important;
    color: white;

  }
  .button2 {
      margin-top: 10px !important;
      margin: 8px !important;
      color: white !important;
      font-weight: bold !important;
      border-color: white !important;;

  }