/***    General     ***/
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Roboto", sans-serif
}

body {
    margin: 0 auto
}

.m-5-auto {
    margin: 5rem auto
}

.primary-button {
    margin-top: 5rem;
    margin-right: 1rem;
    padding: .6rem;
    width: 10rem;
    background: #222;
    border: none;
    color: #fff;
    font-size: 1.2rem;
    transition: all .5s;
    cursor: pointer;
    text-transform: capitalize
}

/***    Landing Page     ***/
.main-title,
.main-para {
    color: #f1f1f1
}

.main-title {
    padding-top: 10rem;
    font-size: 5rem;
    font-family: 'Fascinate', cursive;
    text-transform: uppercase
}

.main-para {
    font-size: 2.5rem;
    text-Transform: capitalize
}

#reg_btn span {
    display: inline-block;
    position: relative;
    transition: 0.5s;
}

#reg_btn span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
}

#reg_btn:hover span {
  padding-right: 25px;
}

#reg_btn:hover span:after {
  opacity: 1;
  right: 0;
}

/***    Login Page     ***/
h2 {
    font-weight: 300
}

.text-center {
    /* background-image: url("https://cdn.wallpapersafari.com/7/1/wVEOrk.jpg"); */
    height: 100%;

}
.login-block {
    align-items: center;
    justify-content: center;
    text-align: center;
    -ms-transform: translateY(50%);
    transform: translateY(50%);

}

form {
    display: inline-block;
    background: #f3f3f3;
    border: 1px solid #ddd;
    border-radius: 2px;
    padding: 2rem;
    margin: 2rem 0 0rem 0
}

footer {
    margin: 1rem 0 0rem 0
}

form label {
    float: left;
    font-size: .9rem;
    margin: 0;
    padding: 0
}

.right-label {
    float: right;
    cursor: pointer
}

input {
    width: 15rem;
    padding: .3rem;
    border-radius: 5px;
    outline: none;
    border: none
}

#sub_btn {
    display: block;
    width: 100%;
    padding: .3rem;
    border: none;
    background: #222;
    color: #fff;
    font-weight: bold;
    border-radius: 3px;
}

#sub_btn:hover {
    background: #333;
    transition: all .5s
}
#google {
    display: block;
    width: 100%;
    padding: .3rem;
    border: none;
    background: #4c8bf5;
    color: white;
    font-weight: bold;
    margin: .2rem 0 0rem 0;
    border-radius: 3px;
}

#google:hover {
    background: #abc2eb;
    transition: all .5s
}

footer p {
    margin: 0;
    font-size: .9rem
}

/***    Register Page     ***/
#checkbox {
    width: 1rem
}

form span {
    font-size: .8rem
}

#reset_pass_lbl {
    float: left
}

/***    Home Page     ***/
.home-page-title {
    color: #222
}

.login-with-google-btn {
    transition: background-color 0.3s, box-shadow 0.3s;
    padding: 12px 16px 12px 42px;
    border: none;
    border-radius: 3px;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
    color: #757575 !important;
    font-size: 14px;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
    background-color: white !important;
    background-repeat: no-repeat;
    background-position: 12px 11px;
  }
  .login-with-google-btn:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25);
  }
  .login-with-google-btn:active {
    background-color: #eeeeee;
  }
  .login-with-google-btn:focus {
    outline: none;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25),
      0 0 0 3px #c8dafc;
  }
  .login-with-google-btn:disabled {
    filter: grayscale(100%);
    background-color: #ebebeb;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }