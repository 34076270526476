/* background, center, height */
.Profile-container {
    height: 100%;
    display: flex;
    background-color: antiquewhite;

  }

/* academics component */
.Title {
    text-align: center;
    width: fit-content;
    margin: 0 auto !important;
    margin-bottom: 1rem !important;
    color: green !important;
    justify-content: center;
    padding: .2rem;
    border: .4rem double black;
    font-family: Verdana, Geneva, Tahoma, sans-serif !important;
    font-weight: bolder !important;
}

/* activities section */
.activities {
    justify-content: center;
    margin: 0 auto !important;
}